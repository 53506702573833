import React, {useEffect, useState} from 'react';
import { Grow } from '@material-ui/core'
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EventIcon from '@material-ui/icons/Event';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Status from '../Status';
import Download from 'downloadjs';

function downloadCsv(courseId)
{
    const options =  {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ id: courseId })
      };

    const csv = fetch('https://backend.yogameetsweggis.ch/admin/course/csv', options).then(res => res.blob()).then(res => Download(res, 'ymw_kurse.csv')).catch(err => console.log(err))
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
}
  
function getComparator(order, orderBy) {
return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
const stabilizedThis = array.map((el, index) => [el, index]);
stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
});
return stabilizedThis.map((el) => el[0]);
}

const headCells = [
{ id: 'name', numeric: false, disablePadding: true, label: 'Kurs' },
{ id: 'slots', numeric: true, disablePadding: false, label: 'Slots' },
{ id: 'countRes', numeric: true, disablePadding: false, label: 'Reservationen' },
{ id: 'roomName', numeric: false, disablePadding: false, label: 'Raum' },
];

function EnhancedTableHead(props) {
const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
};

return (
    <TableHead>
    <TableRow>
        <TableCell padding="checkbox">
        <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all courses' }}
        />
        </TableCell>
        {headCells.map((headCell) => (
        <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
        >
            <TableSortLabel
            active={orderBy === headCell.id}
            direction={orderBy === headCell.id ? order : 'asc'}
            onClick={createSortHandler(headCell.id)}
            >
            {headCell.label}
            {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
            ) : null}
            </TableSortLabel>
        </TableCell>
        ))}
    </TableRow>
    </TableHead>
);
}

EnhancedTableHead.propTypes = {
classes: PropTypes.object.isRequired,
numSelected: PropTypes.number.isRequired,
onRequestSort: PropTypes.func.isRequired,
onSelectAllClick: PropTypes.func.isRequired,
order: PropTypes.oneOf(['asc', 'desc']).isRequired,
orderBy: PropTypes.string.isRequired,
rowCount: PropTypes.number.isRequired,
handleCSVDownload: PropTypes.func.isRequired
};

const useToolbarStyles = makeStyles((theme) => ({
root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
},
highlight:
    theme.palette.type === 'light'
    ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
    : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
        },
title: {
    flex: '1 1 100%',
},
}));

const EnhancedTableToolbar = (props) => {
const classes = useToolbarStyles();
const { numSelected } = props;

return (
    <Toolbar
    className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
    })}
    >
    {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
        {numSelected} ausgewählt
        </Typography>
    ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
        Reservationen
        </Typography>
    )}

    {numSelected > 0 ? (
        <React.Fragment>
        <Tooltip title="csv exportieren">
        <IconButton aria-label="csv exportieren" onClick={props.handleCSVDownload}>
        <EventIcon />
        </IconButton>
    </Tooltip>
    </React.Fragment>
        
    ) : null }
    </Toolbar>
);
};

EnhancedTableToolbar.propTypes = {
numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
root: {
    width: '100%',
},
paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
},
table: {
    minWidth: 750,
},
visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
},
    ausgebucht: {
        '& .MuiTableCell-body': {
        backgroundColor: '#f7755c'
        }
    },
    halbgebucht: {
        '& .MuiTableCell-body': {
        backgroundColor: '#f7cb5c'
        }
    },
    frei: {
        '& .MuiTableCell-body': {
        backgroundColor: '#87fa8f'
        }
    },
}));

export default function Dashboard() {
    const [apidata, setApidata] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [status, setStatus] = useState({ message: '', severity: ''});
    
    useEffect(() => {
        getApiData().then(function(res) {
            if(res.message !== undefined)
            {
                setStatus({message: res.message, severity: res.severity});
            }
            else
            {
                setApidata(res);
            }
        }).then(setIsLoaded(prevState => !prevState));
    }, [])
    
    async function getApiData()
    {
        const options = {
            credentials: 'include',
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }
        };

        const data = fetch('https://backend.yogameetsweggis.ch/admin/dashboard', options).then(res => res.json()).then(function(res) {
            if(res.status = 200)
            {
                return res;
            }
            else
            {
                return {message: 'Error fetching data', severity: 'error'};
            }
        }).catch(error => console.log(error));

        return data;
    }

    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('start');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [createPDF, setCreatePDF] = useState(false);
    const [createICS, setCreateICS] = useState(false);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
        const newSelecteds = apidata.map((n) => n.id);
        setSelected(newSelecteds);
        return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
        );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleCSVDownload = function() {
        downloadCsv(selected);
    }

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, apidata.length - page * rowsPerPage);

    const content = () => (<div className={classes.root}>
      <Paper className={classes.paper}>
          <EnhancedTableToolbar numSelected={selected.length} handleCSVDownload={handleCSVDownload}/>
          <TableContainer>
          <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
          >
              <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={apidata.length}
              />
              <TableBody>
              {
              stableSort(apidata, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                //   let options = { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
                //   let start = new Date(row.start).toLocaleDateString('de-CH', options);
                //   let end = new Date(row.end).toLocaleDateString('de-CH', options);
                  const step = 50;
                  let timeout = index * step;
                  const booked = Math.abs(row.slots - row.countRes);

                  

                  return (
                      <Grow {...(isLoaded ? {timeout: timeout } : {})} in='true' style={{ transformOrigin: '0 0 0' }}>
                      <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    //   className={booked <= 30 && booked !== 0 ? classes.halbgebucht : booked > 30 ? classes.frei : booked === 0 ? classes.ausgebucht : {}}
                      >
                      <TableCell padding="checkbox">
                          <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                          />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                          {row.name}
                      </TableCell>
                      <TableCell align="right">{row.slots}</TableCell>
                      <TableCell align="right">{row.countRes === row.slots ? React.createElement('b', {}, 'Ausgebucht') : row.countRes}</TableCell>
                      <TableCell align="right">{row.roomName}</TableCell>
                      {/* <TableCell align="right">{row.instructor.name}</TableCell> */}
                      </TableRow>
                      </Grow>
                  );
                  })}
              {emptyRows > 0 && (
                  <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                  </TableRow>
              )}
              </TableBody>
          </Table>
          </TableContainer>
          <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={apidata.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          />
      </Paper>
      <Status severity={status.severity} message={status.message} />
      </div>);

    return (
        <React.Fragment>
          {!isLoaded ? 'no content' : content()}
        </React.Fragment>
        
    );
    
}