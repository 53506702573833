import React from 'react';
import Course from './Course';
import TimeGrid from './TimeGrid';
import Room from './Room';

class Calendar extends React.Component
{
    constructor(props) {
        super(props);
        this.state = {
            dailyCourses: [],
            reservations: 0,
            isLoaded: false,
        };
    }
    
    componentDidMount() {
        // let reservations = 0;
        // let dailyCourses = this.queryDailyCourse().then(function(course) {
        //     course.map(function(res) {
        //         this.props.apiresponse.reservations.map(function(reservation) {
        //             if(res.id === reservation.reservations[0].CourseId)
        //             {
        //                 reservations = reservations + 1;
        //             }
        //         })
        //     })});
        this.setState({dailyCourses: [... this.queryDailyCourse()], isLoaded: true});
        console.log('calendar did mount');
    }
    
    queryDailyCourse  = () => {
        
        let returnValue = [];
        
        this.props.apiresponse.courses.map(course => {
            if((course.start).substr(0, 10) === this.props.day)
            {
                return returnValue.push(course);

            }
        });
        
        return returnValue;
    }

    content = () => {
        return(
            <div className="calendar">
                <TimeGrid apidata={this.state.dailyCourses} timeStep={15} visibleSteps={15} />
                <div className='room'></div>
                <Room apidata={this.state.dailyCourses} />
                {this.state.dailyCourses.map(res => {
                    let endTime = (res.end).substr((res.end).indexOf(' ')+1, 5)
                    endTime = endTime.replace(/:+/g, '-');
                    let startTime = (res.start).substr((res.start).indexOf(' ')+1, 5)
                    startTime = startTime.replace(/:+/g, '-');
                    let reservations = 0;
                    this.props.apiresponse.reservations.map(function(reservation) {
                        reservation.reservations.map(function(leReservation)
                        {
                            if(res.id === leReservation.CourseId)
                            {
                                reservations = reservations + 1;
                            }
                        });
                    });
                   
            return (
                <React.Fragment>
                <Course
                    start={startTime}
                    end={endTime}
                    key={res.id}
                    apidata={res}
                    handleLogin={this.props.handleLogin}
                    reservations={reservations}
                    handleReservation={this.props.handleReservation} 
                    login = {this.props.login} 
                    handleLoginForm={this.props.handleLoginForm} />
                </React.Fragment>);
            })}
            </div>
            );
    }

    render() {
        return (
            <div>
            {this.state.isLoaded ? this.content() : null}
            </div>
        );
            }
}

export default Calendar