import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import Link from '@material-ui/core/Link';
import SchoolIcon from '@material-ui/icons/School';
import PersonIcon from '@material-ui/icons/Person';
import Status from './Status';

const useStyles = makeStyles((theme) => ({
    root: {},
    icons: {
        fontSizeSmall: '1.4vh',
        verticalAlign: 'middle',
        paddingRight: '5px',
        paddingBottom: '3px',
    },
    Time: {
        margin: 'auto',
        lineHeight: '2em',
        float: 'left',
        width: '1fr',
        paddingRight: '1em',
    },
    teacher: {
        margin: 'auto',
        lineHeight: '2em',
        float: 'left',
        width: '100%',
        paddingBottom: '0.5em',
    },
    seats: {
        margin: 'auto',
        lineHeight: '2em',
        float: 'left',
        width: '1fr',
        paddingBottom: '0.5em' 
    },
    courseTitle: {
        marginTop: '0px',
    },
    description: {
        paddingBottom: '1em',
        overflow: 'none'
    },
    image:
    {
        width: '100%',
        height: '100%',
    },
    link: {
        textDecoration: 'none',
        color: theme.palette.secondary.main,
        '&:hover': {
            textDecoration: 'underline'
        }

    }
  }));

export default function Course(props) {
    const handleOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };
    
    const [status, setStatus] = useState({
        severity: '',
        message: ''
    });

    const handleReservation = async (action) =>
    {
        if(action === 'login')
        {
            props.handleLoginForm();
        }
        else
        {
            const login = await props.handleLogin().catch(res => console.log(res));

            if(login)
            {
                const options = 
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    // withCredentials: true,
                    credentials: 'include',
                    body: JSON.stringify({ courseId:String(props.apidata.id) })
                }

                const result = await fetch("https://backend.yogameetsweggis.ch/seq/course/" + action + "/reservation", options).then(res => res.json()).then(function(res) {
                    if(res.result !== undefined)
                    { 
                        if(res.severity !== 'error')
                        {
                            props.handleReservation(props.apidata.id, action)
                            if(action === 'make')
                            {
                                setReservations(prevState => prevState + 1);
                            }
                            else
                            {
                                setReservations(prevState => prevState - 1);
                            }
                        }
                        setStatus({severity: res.severity, message: res.result});
                    }
                    }).catch(err => console.log('le error: ' + err));
                
                    
            }
            else
            {
                return false
            }
        }
    }
    
    const [open, setOpen] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        const img = new Image();
        img.src = '../assets/course/' + props.apidata.image;
    }, [props.apidata])

    let startTime = props.start;
    let endTime = props.end;
    let bgImg;
    try {
        bgImg = require('../assets/course/'+props.apidata.image);
    }
    catch(e) {
        bgImg = require('../assets/course/default.jpg');
        //console.log(console.error("image loading: ", e.message));
    }
    let backgroundImage = {
        minHeight: '200px',
        minWidth: '200px',
        maxHeight: '400px',
        maxWidth: '800px',
        width: '100%',
        height: '100%',
        display: 'inline-block',
        marginBottom: '1em',
        backgroundImage: 'url('+bgImg+')',
        backgroundPosition: 'center center',
        backgroundSize: '100%',
        backgroundRepeat: 'no-repeat',

    };

    const [reservierenButton, setReservierenButton] = useState('');
    const [reservations, setReservations] = useState(0);

    useEffect(() => {
        if(props.login.loggedIn === true) {
           setReservierenButton(props.handleReservation(props.apidata.id, 'check'));
        }
        else
        {
            setReservierenButton('login')
        }
    }, [props])

    useEffect(() => {
        setReservations(props.reservations);

    }, [props.reservations])
    
    // useEffect(() => {
    //     setReservations(props.reservations)
    // })

    function resButton()
    {
        if(reservierenButton !== 'login' && props.login.validTicket !== null && props.login.loggedIn === true)
        {
            const validTickets = props.login.validTicket.split(", ")
            let returnvalue = validTickets.map(function(ticket){
                if(ticket.substr(0,10) === props.apidata.start.substr(0,10))
                {
                    if(reservations < props.apidata.slots || reservierenButton === 'delete')
                    {
                        return <Link onClick={() =>handleReservation(reservierenButton)} color="secondary">{reservierenButton === 'make' ? 'Reservieren' : reservierenButton === 'delete' ? 'Reservation stornieren' : 'Anmelden' }</Link>
                    }
                }
            })
            if(reservations > props.apidata.slots && reservierenButton !== 'delete')
            {
                return null
            }
            else
            {
                return returnvalue;
            }
        }
        else
        {
            return <Link onClick={() =>handleReservation(reservierenButton)} color="secondary">{reservierenButton === 'make' ? 'Reservieren' : reservierenButton === 'delete' ? 'Reservation stornieren' : 'Anmelden' }</Link>
        }
    }

    startTime = startTime.replace(/-+/g, ':');
    endTime = endTime.replace(/-+/g, ':');
    return(
        <React.Fragment>
            <div className={'event ' + classes.root} data-room={'room-' + props.apidata.RoomId} data-start={props.start} data-end={props.end} onClick={handleOpen}>
                <h2 className={classes.courseTitle} dangerouslySetInnerHTML={{__html: props.apidata.name}}></h2>
                <div className={classes.Time}>
                    <AccessTimeIcon className={classes.icons} fontSize={'small'}/>Start: {startTime}<br />
                    <AccessTimeIcon className={classes.icons} fontSize={'small'}/>Ende: {endTime}</div>
                    <div className={classes.teacher}><SchoolIcon className={classes.icons} /><Link href={props.apidata.url} color="secondary">{props.apidata.Instructor.firstname} {props.apidata.Instructor.lastname}</Link></div>
                </div>
            <Dialog onClose={handleClose} open={open}>
                <DialogTitle onClose={handleClose}>
                {props.apidata.name}
                </DialogTitle>
                <DialogContent dividers>
                    <div style={backgroundImage}></div>
                <Typography gutterBottom>
                    <div className={classes.teacher} style={{textAlign: 'right'}}><SchoolIcon className={classes.icons} /><Link href={props.apidata.url} color="secondary">{props.apidata.Instructor.firstname} {props.apidata.Instructor.lastname}</Link></div>
                    <div className={classes.description} dangerouslySetInnerHTML={{__html: props.apidata.description}}></div>
                    <div className={classes.Time}>
                        <AccessTimeIcon className={classes.icons}/>Start: {startTime}<br />
                        <AccessTimeIcon className={classes.icons}/>Ende: {endTime}</div>
                    <div className={classes.seats}>
                        <PersonIcon className={classes.icons}/>Verfügbare Plätze: {props.apidata.slots - reservations}<br />
                        {props.login !== void(0) ? resButton() : null}
                    </div>
                </Typography>
                <Typography gutterBottom></Typography>
                </DialogContent>
                <DialogActions>
                <Button autoFocus onClick={handleClose} color="primary" variant="contained">
                    Schliessen
                </Button>
                </DialogActions>
            </Dialog>
            <Status severity={status.severity} message={status.message} />
            
            {/* <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 500,
                }}
            >
                <Fade in={open}>
                <Card className={classes.root} variant="outlined">
                    <CardContent>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                        {props.name}
                        </Typography>
                        <Typography variant="body2" component="p">
                        {props.description}<br /><br />Start: {startTime}<br/>Ende: {endTime}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button variant="contained" color="primary">Kurs reservieren</Button>
                    </CardActions>
                </Card>
                </Fade>
            </Modal> */}
        </React.Fragment>
    );
}