import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Status from './Status';


const useStyles = makeStyles((theme) => ({
    label: {
        color: theme.palette.text.secondary 
    }
}), { name: 'MuiFormControlLabel'});

export default function LoginForm(props) {
    const classes = useStyles();
    
    const [register, setRegister] = useState(true);
    const [lostPassword, setLostPassword] = useState(false);
    const [status, setStatus] = useState({message: '', severity: ''});
    const [login, setLogin] = useState({ email: '', password: '', eguma: ''})

    function handleRegister()
    {
        setRegister(pervState => !pervState);
        setLogin({ email: '', password: '', eguma: ''})
    }


    const handleLogin = async function()
    {
        if(login.email.match("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$"))
        {
          if(lostPassword === true)
          {
            const options = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ eMail: login.email })
            };
            fetch('https://backend.yogameetsweggis.ch/login/sendpassword', options).then(res => res.json()).then(function(res) {
              setStatus({message: res.message, severity: res.severity});
              if(res.status = 200)
              {
                setLostPassword(false);
                props.handleLoginForm();
              }
            })
          }
          else 
          {
            const loginState = await props.handleLogin(login.email, login.password, login.eguma);
            if(loginState)
            {
            setLogin({ email: '', password: '', eguma: ''});
            } 
          }
          
        }
        else
        {
          props.setLogin({loggedIn: false, message: 'Ungültige E-Mail Adresse'});
        }

    }
    const restorepw = (<React.Fragment>
      <TextField
        required
        autoFocus
        margin="dense"
        id="email"
        label="E-mail Addresse"
        type="email"
        value={login.email}
        onChange={e => setLogin({...login,  email: e.target.value})}
        fullWidth
      />
    </React.Fragment>)
    const registerForm = (<React.Fragment>
      <TextField
        autoFocus
        required
        margin="dense"
        id="eguma"
        label="Ticket Nummer"
        type="text"
        value={login.eguma}
        onChange={e => setLogin({...login,  eguma: e.target.value})}
        fullWidth
      />
      <TextField
        required
        margin="dense"
        id="email"
        label="E-mail Addresse"
        type="email"
        value={login.email}
        onChange={e => setLogin({...login,  email: e.target.value})}
        fullWidth
      />
      <TextField
        required
        margin="dense"
        id="passwort"
        label="Passwort"
        type="password"
        value={login.password}
        onChange={e => setLogin({...login,  password: e.target.value})}
        fullWidth
      /></React.Fragment>);

    const loginForm = (<React.Fragment>
        <TextField
            required
          autoFocus
          margin="dense"
          id="email"
          label="E-mail Addresse"
          type="email"
          value={login.email}
          onChange={e => setLogin({...login,  email: e.target.value})}
          fullWidth
        />
        <TextField
            required
          margin="dense"
          id="passwort"
          label="Passwort"
          type="password"
          value={login.password}
          onChange={e => setLogin({...login,  password: e.target.value})}
          fullWidth
        /></React.Fragment>);

  return (
    <div>
      <Dialog open={props.showLoginForm} onClose={props.handleLoginForm} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{!lostPassword ? register ? "Anmelden" : "Registrieren" : 'Passwort zurücksetzen'}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {!lostPassword ? 'Um Kursreservationen tätigen zu können ist ein Benutzerkonto erforderlich. Mit dem Umschalter können Sie zwischen "Anmelden" für bestehende Benutzer und "Registrieren" um ein neues Benutzerkonto zu erstellen umschalten.': 'Geben Sie Ihre E-Mail Adresse ein, danach erhalten Sie eine Mail um Ihr Passwort zurückzusetzen.'}
                </DialogContentText>
                <Typography id="errorMessage" color="error">{props.login.message}</Typography>
                {lostPassword ? restorepw : register ? loginForm : registerForm }
                {!lostPassword ? (<FormControlLabel
                    control={
                        <Switch
                            checked={register}
                            onChange={handleRegister}
                            name="registerSwitch"
                            color="primary"
                        />
                    }
                    label={register ? 'Anmelden' : 'Registrieren'}
                />) : null}
                {!lostPassword ? <Link onClick={() => setLostPassword(true)} color="secondary">Passwort vergessen?</Link> : null}
            </DialogContent>
            <DialogActions>
            <Button onClick={props.handleLoginForm} color="primary" variant="contained">
                Abbrechen
            </Button>
            <Button onClick={e => handleLogin(e)} color="secondary" variant="contained">
                {!lostPassword ? register ? "Anmelden" : "Registrieren" : 'Zurücksetzen'}
            </Button>
            </DialogActions>
      </Dialog>
      {status.message.length > 0 ? <Status message={status.message} severity={status.severity} /> : null }
    </div>
  );
}
