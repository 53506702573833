import React, {useState, useEffect} from 'react';
import { useParams } from "react-router-dom";
import { Container, Paper, Input, TextField, FormControl, FormHelperText, DialogActions, Button } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Status from './Status';

const useStyle = makeStyles(theme => ({
  form: {
      padding: '10px',
      marginTop: theme.spacing(2)
  }
}))

export default function ResetPassword(props)
{
    let {id} = useParams();
    const [login, setLogin] = useState({resetPassword: id, password: '', password2: '', error: false});
    const [status, setStatus] = useState({message: '', severity: '', redirect: false});

    const classes = useStyle();

    useEffect(()=> {
        if(status.severity === 'success')
        {
            setTimeout(()=> {
                setStatus({...status, redirect: true})
            }, 1000)
        }
    }, [status])

    function handlePassword(e)
    {
        e.preventDefault();
        if(login.password === login.password2)
        {
            setLogin({...login, error: false});

            const options = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ password: login.password, resetPassword: login.resetPassword })
              };
              fetch('https://backend.yogameetsweggis.ch/login/resetpassword', options).then(res => res.json()).then(function(res) {
                if(res.status = 200)
                {
                  setStatus({message: 'Passwort erfolgreich zurückgesetzt', severity: 'success'});
                }
                else
                {
                    setStatus({message: 'Passwort konnte nicht zurückgesetzt werden', severity: 'error'});
                }
              })
        }
        else
        {
            setLogin({...login, error: true});
        }
    }

    return(
        <React.Fragment>
            <Container maxWidth="sm">
                <Paper className={classes.form}>
                    <form onSubmit={(e) => handlePassword(e)}>
                    <Input type="hidden" value={id} />
                    <TextField
                    required
                    margin="dense"
                    id="passwort"
                    label="Neues Passwort"
                    type="password"
                    value={login.password}
                    onChange={e => setLogin({...login,  password: e.target.value})}
                    fullWidth
                />
                <FormControl error fullWidth>
                        <TextField
                        required
                        margin="dense"
                        id="passwort2"
                        label="Wiederholen Sie Ihr Passwort"
                        type="password"
                        aria-describedby="password-error-text"
                        value={login.password2}
                        onChange={e => setLogin({...login,  password2: e.target.value})}
                        fullWidth
                    />
                    {login.error ? <FormHelperText id="password-error-text">Passwörter stimmen nicht überein</FormHelperText> : null }
                </FormControl>
                <DialogActions>
                <Button type="submit" color="secondary" variant="contained">
                Passwort setzen
                </Button>
                </DialogActions>
                </form>
                </Paper>
            </Container>
            <Status message={status.message} severity={status.severity}/>
            {status.redirect === true ? <Redirect exact to="/" /> : null }
        </React.Fragment>
    )
}