import React, { useState } from 'react';
import Download from 'downloadjs';
import { Container, Paper, TextField, DialogActions, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Status from '../Status';

const useStyle = makeStyles(theme => ({
    form: {
        padding: '10px',
        marginTop: theme.spacing(2)
    }
  }))

export default function ContactTracing()
{
    const [eGuma, setEGuma] = useState({ eGuma: ''});
    const [status, setStatus] = useState({message: '', severity: ''});

    const classes = useStyle();

    function handleContactTracing(e)
    {
        e.preventDefault();

        const options = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ eGuma: eGuma.eGuma })
          };

          console.log(JSON.stringify({ eGuma: eGuma.eGuma }))

        fetch('https://backend.yogameetsweggis.ch/admin/contacttracing', options).then(res => res.blob()).then(res => {
            Download(res, 'contacttracing.csv')
            setStatus({message: 'Download wird gestartet...', severity: 'success'});
        }).catch(() => setStatus({message: 'Download fehlgeschlagen', severity: 'error'}))
    }

    return(
        <React.Fragment>
            <Container maxWidth="sm">
                <Paper className={classes.form}>
                    <form onSubmit={(e) => handleContactTracing(e)}>
                    <TextField
                    required
                    margin="dense"
                    id="eGuma"
                    label="eGuma Ticketnummer"
                    type="text"
                    value={eGuma.eGuma}
                    onChange={e => setEGuma({ eGuma: e.target.value })}
                    fullWidth
                />
                <DialogActions>
                <Button type="submit" color="secondary" variant="contained">
                CSV herunterladen
                </Button>
                </DialogActions>
                </form>
                </Paper>
            </Container>
            <Status message={status.message} severity={status.severity}/>
        </React.Fragment>
    );
}