import React, { useEffect, useState } from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';

const useStyles = makeStyles((theme) => ({
    root: {
        fontFamily: theme.typography.fontFamily,
        fontSize: '11pt',
        '& h3': {
            fontSize: '14pt',
            textAlign: 'center',
        }
    },
    table: {
        minWidth: 650,
        maxWidth: '200mm',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: '5px',
        cellPadding: '2px',
        '& th': {
        textAlign: 'left'
        },
        '& tr': {
            paddingBottom: '5px'
        }
    },
  }));

export default function CreatePDF(props)
{
    const [courses, setCourses] = useState([]);
    const [content, setContent] = useState('');

    const classes = useStyles();

    useEffect(()=> {
        if(props.apidata !== undefined)
        {
            orderByStart(props.apidata).then(res => {
                return splitByDay(res);
            }).then(res => {
                setCourses(res)
            })
        }
    }, [props.apidata])
    
    useEffect(() => {
        if(courses.length >0)
        {
            setContent(createContent())

        }
    }, [courses])

    useEffect(() => {
        if(content)
        {
            props.statusHandler({message:'PDF wird erstellt.', severity:'success'});
            setTimeout(makePDF, 1000)
            // setTimeout(props.setCreatePDF(false), 6000);
        }
    }, [content])
    
    const orderByStart = (apidata) => new Promise(function(resolve, reject)
    {
         /* Sort Course by start ASC*/
         apidata.sort(function(a, b){
            let x = a.start.toLowerCase();
            let y = b.start.toLowerCase();
            if (x < y) {return -1;}
            if (x > y) {return 1;}
        });

        resolve(apidata);
    });

    const selectDays = (apidata) => new Promise(function(resolve, reject) {
        let output = [];
        let isUnique = true;
         
        apidata.map(day => {
          isUnique = true
          if(output.length <= 0)
          {
            output.push((day.start).substr(0, 10));
          }
          for(let i = 0; i < output.length; i++) {
            if(output[i] === (day.start).substr(0, 10)) {
              isUnique = false;
            }
          }
            if(isUnique) {
              output.push((day.start).substr(0, 10));
            }
    
          });
          resolve(output);
      });

    const splitByDay = (apidata) => new Promise(function(resolve, reject)
    {
        selectDays(apidata).then(res => {

        let newObj = [];
        let i = 0
        res.map((day, index) => {
            newObj.push({'day': day, 'courses': []});
            apidata.map((course) => {
                if(day === (course.start).substr(0, 10))
                {
                    newObj[i]['courses'].push(course);
                }
            })
            i++;
        })

        resolve(newObj);

    });

    });

    async function makePDF()
    {
        const pdf = new jsPDF();
        let container = document.getElementById("capture");

        if(window.screen.width < 1024) {
            document.documentElement.setAttribute("content", "width=1200px");
        }
                html2canvas(container, {removeContainer: true,
                    backgroundColor: null,
                    imageTimeout: 15000,
                    logging: true,
                    scale: 1,
                    useCORS: true}).then(function(canvas) {
                
                const imgData = canvas.toDataURL('image/png')
                const imgWidth = 210;
                const imgHeight = canvas.height * imgWidth / canvas.width;
                    pdf.addImage(imgData, 'PNG', 5, 10, imgWidth, imgHeight, undefined,'FAST')
                    setTimeout(() => pdf.save("download.pdf"), 3000); 
                    
            }).catch((err) => console.log(err))

            if(window.screen.width < 1024) {
                document.documentElement.setAttribute("content", "width=device-width, initial-scale=1");
              }
        props.setCreatePDF(false);        
    }

    const createContent = () => {
        return(<React.Fragment>
            {courses.map((day, index) =>{
                let options = { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric' };
                let date = new Date(day.day).toLocaleDateString('de-CH', options);
                return(<div id={"day"+index}>
                <h3>{date}</h3>
                <Table className={classes.table}>
                        <thead>
                        <tr key={index}>
                            <th width="60%">Kurs</th>
                            <th>Start</th>
                            <th>Ende</th>
                            <th>Raum</th>
                        </tr>
                        </thead>
                            {day.courses.map((course, index) => {
                                let endTime = (course.end).substr((course.end).indexOf(' ')+1, 5);
                                let startTime = (course.start).substr((course.start).indexOf(' ')+1, 5);
                                return(<React.Fragment>
                                    <tbody>
                                    <tr key={index}>
                                        <td>{course.name}</td>
                                        <td>{startTime}</td>
                                        <td>{endTime}</td>
                                        <td>{course.Room.name}</td>
                                    </tr>
                                    </tbody>
                                </React.Fragment>)
                            })}
                        
                </Table>
                </div>)
            })}
            </React.Fragment>
        )
    }

    return(
        <div id="capture" className={classes.root} style={({
            backgroundColor: '#ffffff',
            maxWidth: '210mm',
            maxHeight: '297mm',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '3000px',
            height: '3000px',
            position: 'absolute',
            top: '0',
            left: '-9999px'
            
        })}>
              {content ? content : null}
              
        </div>
    );
}