import React, { useState, useEffect } from 'react';
import './TimeGrid.scss';

export default function TimeGrid(props)
{

    const [slots, setSlots] = useState([]);
    const [times, setTimes] = useState({start: '', end: []});
    const [rows, setRows] = useState(false);

    useEffect(() => {
        setTimes(queryLastCourse());
    }, [props])

    useEffect(() =>
    {
        setSlots(createSlots());
    }, [times, props]);

    useEffect(() => {
        if(slots.length > 1 && !rows)
        {
            createRows();
            setRows(true);
        }
    }, [slots, props])


    function createSlots()
    {
        let slots = [times.start];
        let time = times.start*60;

        for(let i = 0; time<=(times.end[0]*60+times.end[1]%60); i++)
        {
            var hh = Math.floor(time/60);
            var mm = (time%60);
            slots[i] = ("0" + (hh % 24)).slice(-2) + ':' + ("0" + mm).slice(-2);
            time = time + props.timeStep;
        }

        return slots;
    }

    function createRows() {

        //Find first and last timeslot

        //Create CSS Grid Template
        let container = document.createElement('style');

        if(document.getElementById('calendarRowTemplate') === null)
        {
            container.type = 'text/css';
            container.setAttribute('id', 'calendarRowTemplate');
        }
        else
        {
            container = document.getElementById('calendarRowTemplate');
        }
        
        let css = ".calendar { grid-template-rows: [room] auto ";
        let cssStart = '', cssEnd = '', time;

        slots.map(slot => {
            time = slot.replace(/:+/g, '-');

            //set height of non visible timesteps to 0px
            if(slot.substr(3,2)%props.visibleSteps === 0 || slot === (times.end[0] + ':' + times.end[1]))
            {
                css = css + '[line-' + time +'] 0.01fr '
            }
            else
            {
                css = css + '[line-' + time +'] 0.001fr '
            }
            cssStart = cssStart +  '.from-' + time + ' { --start-time: line-' + time + '}; .to-' + time + '{ --end-time: line-' + time + ' }\n';
            cssEnd = cssEnd + '.event[data-start="' + time + '"] { --start-time: line-' + time + '; }\n.event[data-end="' + time + '"] { --end-time: line-' + time + '; }\n';
        });

        css = css + '}\n';

        container.innerHTML = css + cssStart + cssEnd;
        document.getElementsByTagName('head')[0].appendChild(container);

    }

    function queryLastCourse()
    {
        /* Sort by starttime desc*/
        let courses = [...props.apidata];

        courses.sort(function(a, b){
            let x = a.start.toLowerCase();
            let y = b.start.toLowerCase();
            if (x < y) {return -1;}
            if (x > y) {return 1;}
        });
        
        let start = courses[0].start;
        let end = courses[courses.length-1].end;
        
        if((start.substr(11,1)).indexOf('0') === -1)
        {
            start = start.substr(11,2)
        }
        else
        {
            start = start.substr(12,1)
        }

        /* Sort by endtime desc*/

        courses.sort(function(a, b){
            let x = a.end.toLowerCase();
            let y = b.end.toLowerCase();
            if (x < y) {return -1;}
            if (x > y) {return 1;}
        });

        end = (end.substr(end.indexOf(' ')+1, 5)).split(':');

        return {start: start, end: [...end]};
    }

    return (
        <React.Fragment>
            {slots.map(slot => {
                let slotDash = slot.toString()
                slotDash = slotDash.replace(/:+/g, '-');
                if(slot.substr(3,2)%props.visibleSteps === 0 || slot === (times.end[0] + ':' + times.end[1]))
                {
                    return (<div className={'time from-' + slotDash} key={slot}>{slot}</div>);
                }
                else
                {  
                    return (<div className={'time from-' + slotDash} key={slot}></div>);
                }
            })}
        </React.Fragment>
    );
}
