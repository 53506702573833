import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: { },
});

export default function Room(props) {

    const [rooms, setRooms] = useState([]);
    const classes = useStyles();

    function createColumns() {

        //Find first and last timeslot

        //Create CSS Grid Template
        let container = document.createElement('style');

        if(document.getElementById('calendarColTemplate') === null)
        {
            container.type = 'text/css';
            container.setAttribute('id', 'calendarColTemplate');
        }
        else
        {
            container = document.getElementById('calendarColTemplate');
        }
        
        let css = ".calendar { grid-template-columns: [time] auto ";
        let cssRoom = '', cssEvent = '';

        rooms.map(room => {
            css = css + '[room-' + room.Room.id +'] 1fr '

                cssRoom = cssRoom + '.room-' + room.Room.id + ' { --room: room-' + room.Room.id + '; }\n';
              
                cssEvent = cssEvent + '.event[data-room="room-' + room.Room.id + '"] { --room: room-' + room.Room.id + '; }\n'
        });

        css = css + '}\n';

        container.innerHTML = css + cssRoom + cssEvent;
        document.getElementsByTagName('head')[0].appendChild(container);

    }
    
    function extractRooms()
    {
        let output = [];
        let isUnique = true;

        /* Sort Rooms by alphabet ASC*/
        props.apidata.sort(function(a, b){
            let x = a.Room.name.toLowerCase();
            let y = b.Room.name.toLowerCase();
            if (x < y) {return -1;}
            if (x > y) {return 1;}
        });
        
        props.apidata.map(room => {
            isUnique = true

            if(output.length <= 0)
            {
                output.push(room);
            }

            for(let i=0; i<output.length; i++) {
                if(output[i].Room.id === room.Room.id) {
                    isUnique = false
                }
            }

            if(isUnique) {
                output.push(room);
            }
        });

        return output;
    }

    useEffect(() => {
        setRooms(extractRooms());
    }, [props.apidata])

    useEffect(() => {
        createColumns();
    },[rooms])


    return(
        <React.Fragment>
            { rooms.map(room => {
           return <div className={'room room-' + room.RoomId + ' ' + classes.root} ><h5>{room.Room.name}</h5></div>
        })}
        </React.Fragment>
    );
}