import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import AppBar from '@material-ui/core/AppBar';
import DaySelector from './DaySelector'
import {
  HashRouter as Router,
  Switch,
  Route, Redirect
} from "react-router-dom";
import MenuItems from './MenuItems';
import Reservationen from './Reservationen';
import ResetPassword from './ResetPassword';
import Dashboard from './admin/Dashboard'
import ContactTracing from './admin/ContactTracing';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    
      drawerPaper: {
      
    },
    drawerHeader: {
      background: theme.palette.primary.main,
      display: 'flex',
      alignItems: 'center',
      minWidth: drawerWidth,
      // necessary for content to be below app bar
      justifyContent: 'flex-end',
    },
    listItem: {
      background: '#fff',
      '&:hover': { background: theme.palette.primary.light }
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0
    },
    content: {
      flexGrow: 1,
      paddingTop: theme.spacing(6),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: 0,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: drawerWidth,
    },
  }));

function MainMenu(props)
{   
  const [drawerOpen, setDrawerOpen] = useState(false);

  function handleDrawerChange()
  {
    setDrawerOpen(prevVal => !prevVal);
  }

  const classes = useStyles();

    return (
      <React.Fragment>
        <AppBar position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: drawerOpen,
        })}>
        <Toolbar variant="dense">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerChange}
              edge="start"
              className={'something'}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Router>
          <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={drawerOpen}
          classes={{paper: classes.drawerPaper}}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerChange}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <MenuItems handleLoginForm={props.handleLoginForm} handleLogin={props.handleLogin} login={props.login} handleLogout={props.handleLogout} handleDrawerChange={handleDrawerChange} />
        </Drawer>
        <main className={clsx(classes.content, {
          [classes.contentShift]: drawerOpen,
        })}>
          
        <Switch>
          {/* <Route exact path="/">
            <DaySelector handleLoginForm={props.handleLoginForm} showLoginForm={props.openLogin} handleLogin={props.handleLogin} login={props.login} handleReservation={props.handleReservation}/>
          </Route>
        <Route path="/reservationen">
          { props.login.loggedIn ? <Reservationen handleLogin={props.handleLogin} handleReservation={props.handleReservation} /> : <Redirect exact to="/" /> }
        </Route> */}
        <Route path="/admin/dashboard">
          { props.login.loggedIn && props.login.admin === true ? <Dashboard /> : <Redirect exact to="/" /> }
        </Route>
        <Route path="/admin/contacttracing">
          { props.login.loggedIn && props.login.admin === true ? <ContactTracing /> : <Redirect exact to="/" /> }
        </Route>
        <Route path="/reset/:id" child="<ResetPassword />">
          <ResetPassword />
        </Route>
        </Switch>
        </main>
      </Router>
      
    </React.Fragment>
    );
}

export default MainMenu;