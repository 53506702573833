import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { NavLink } from "react-router-dom";
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import EventSeatIcon from '@material-ui/icons/EventSeat';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import DateRangeIcon from '@material-ui/icons/DateRange';
import BuildIcon from '@material-ui/icons/Build';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';

const useStyles = makeStyles((theme) => ({
    primary: {
      textTransform: 'uppercase',
      '&:hover': {
        color: theme.palette.secondary.main
    }
    }
  }), { name: 'MuiListItemText' });

  const linkStyles = makeStyles((theme) => ({
    primary: {
      color: theme.palette.text.primary,
      textDecoration: 'none'
    },
    active: {
        color: theme.palette.secondary.main
    }
  }));

export default function MenuItems(props) {
    
    const listItemStyle = useStyles();
    const linkStyle = linkStyles();

    const handleLogout = async function()
    {
        await props.handleLogout();
        props.handleDrawerChange();
    }

    const loggedIn = (<React.Fragment>
        <List>
            <NavLink to="/" exact={true} className={linkStyle.primary} activeClassName={linkStyle.active} onClick={props.handleDrawerChange}>
                <ListItem button>
                    <ListItemIcon><DateRangeIcon/></ListItemIcon>
                    <ListItemText primary={'Programm'} />
                </ListItem>
            </NavLink>
        </List>
        <Divider />
        <List>
            <NavLink to="/reservationen" className={linkStyle.primary} activeClassName={linkStyle.active} onClick={props.handleDrawerChange}>
                <ListItem button>
                    <ListItemIcon><EventSeatIcon/></ListItemIcon>
                    <ListItemText primary={'Reservationen'} />
                </ListItem>
            </NavLink>
        </List>
        <Divider />
        <List>
            <ListItem button onClick={handleLogout}>
              <ListItemIcon><ExitToAppIcon/></ListItemIcon>
              <ListItemText primary={'Logout'}/>
            </ListItem>
        </List>
        <Divider />
        </React.Fragment>);

    const loggedOut = (<React.Fragment>
        <List>
            <ListItem button onClick={props.handleLoginForm}>
              <ListItemIcon><VpnKeyIcon /></ListItemIcon>
              <ListItemText primary={'Login'} />
            </ListItem>
        </List>
        <Divider />
        </React.Fragment>);

    const admin = (<React.Fragment>
        <List>
            <NavLink to="/admin/dashboard" className={linkStyle.primary} activeClassName={linkStyle.active} onClick={props.handleDrawerChange}>
                <ListItem button>
                    <ListItemIcon><BuildIcon/></ListItemIcon>
                    <ListItemText primary={'Admin'} />
                </ListItem>
            </NavLink>
        </List>
        <Divider />
        <List>
            <NavLink to="/admin/contacttracing" className={linkStyle.primary} activeClassName={linkStyle.active} onClick={props.handleDrawerChange}>
                <ListItem button>
                    <ListItemIcon><LocalHospitalIcon/></ListItemIcon>
                    <ListItemText primary={'Contact Tracing'} />
                </ListItem>
            </NavLink>
        </List>
        <Divider />
    </React.Fragment>)
    
    return(
        <React.Fragment>
            {props.login.loggedIn === true ? loggedIn : loggedOut}
            {props.login.admin === true ? admin : null}
        </React.Fragment>

    )
}